import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '../hooks';

import Layout from '../components/Layout';
import Container from '../components/Container';

const SecondPage: React.FC = () => {
  const { companyName, companyUrl, authorName, authorUrl, siteDescription } = useSiteMetadata();

  return (
    <Layout pageName="about">
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>About</title>
      </Helmet>
      <Container type="content">
        <h1>About</h1>

        <h2>{companyName}</h2>
        <p>{siteDescription}</p>
        {/* <p>
          <a href={companyUrl}>View on Github</a>
        </p> */}

        <h2>Created By</h2>
        <p>
          <a href={authorUrl}>{authorName}</a>
        </p>

        <iframe src="https://store.steampowered.com/widget/1052990/" frameBorder="0" width="646" height="200"></iframe>
      </Container>
    </Layout>
  );
};

export default SecondPage;
